<template>
  <v-chip
    v-bind="$attrs"
    :color="testing_method.color"
    small
    label
    class="pr-2"
  >
    <v-icon
      x-small
      class="ml-n1 mr-1"
    >
      {{ testing_method.icon }}
    </v-icon>
    {{ testing_method.text }}
  </v-chip>
</template>
<script>
export default {
  props: {
    method: {
      type: String,
      default: null,
    },
  },
  computed: {
    testing_method () {
      return this.$testing_methods.find(x => x.value == this.method) || {}
    },
  },
}
</script>
